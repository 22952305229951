const gtmClick = (buttonName) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "button_click",
        button_name: buttonName
    });
};

const gtmView = (sectionName) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: "section_view",
        section_name: sectionName
    });
};

export { gtmClick, gtmView };