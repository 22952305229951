import { useRef, useState } from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import CustomPagination from "./CustomPagination";

const SliderKV = () => {
    const splideRef = useRef();

    const [moved, setMoved] = useState(false);

    return (
        <section id="taiwanway" className={`section-KV${moved ? "" : ""}`}>
            <Splide
                ref={splideRef}
                hasTrack={false}
                onMove={() => {
                    setMoved(true);
                }}
                onMoved={() => {
                    setMoved(false);
                }}
                options={{
                    type: "loop",
                    arrows: false,
                    pagination: false,
                }}
            >
                <SplideTrack>
                    <SplideSlide>
                        <img className="kv-pc" src="/images/pic-KV_1.jpg?20240918" alt="pic-KV_1" />
                        <img className="kv-mo" src="/images/pic-KV-mobile_1.jpg?20240918" alt="pic-KV-mobile_1" />
                    </SplideSlide>
                    <SplideSlide>
                        <img className="kv-pc" src="/images/pic-KV_2.jpg?20240918" alt="pic-KV_2" />
                        <img className="kv-mo" src="/images/pic-KV-mobile_2.jpg?20240918" alt="pic-KV-mobile_2" />
                    </SplideSlide>
                    <SplideSlide>
                        <img className="kv-pc" src="/images/pic-KV_3.jpg?20240918" alt="pic-KV_3" />
                        <img className="kv-mo" src="/images/pic-KV-mobile_3.jpg?20240918" alt="pic-KV-mobile_3" />
                    </SplideSlide>
                </SplideTrack>
            </Splide>

            <div className="btn-arrows">
                <button className="btn-prev" onClick={() => {
                    splideRef.current?.splide.go("-1");
                }}>
                    <svg width="19" height="38" viewBox="0 0 19 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.4 19L18.3425 34.904C18.5552 35.1423 18.667 35.4413 18.678 35.801C18.6887 36.1603 18.5822 36.4622 18.3585 36.7065C18.1018 36.9508 17.794 37.073 17.435 37.073C17.076 37.073 16.7747 36.95 16.531 36.704L0.7735 20.9075C0.486167 20.6332 0.2855 20.332 0.1715 20.004C0.0571667 19.6757 0 19.3442 0 19.0095C0 18.6748 0.0538333 18.3403 0.1615 18.006C0.2695 17.6713 0.4735 17.354 0.7735 17.054L16.5425 1.2845C16.7992 1.0385 17.1018 0.917335 17.4505 0.921002C17.7992 0.925002 18.0965 1.05 18.3425 1.296C18.5552 1.54234 18.6635 1.84234 18.6675 2.196C18.6715 2.55 18.5632 2.85 18.3425 3.096L2.4 19Z" fill="#B6B6B6"/>
                    </svg>
                </button>
                <button className="btn-next" onClick={() => {
                    splideRef.current?.splide.go("+1");
                }}>
                    <svg width="19" height="38" viewBox="0 0 19 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.3061 18.9385L0.36358 3.0345C0.150914 2.79617 0.0380808 2.49817 0.0250808 2.1405C0.0124141 1.78283 0.125247 1.49367 0.36358 1.273C0.609914 1.027 0.911913 0.903999 1.26958 0.903999C1.62725 0.903999 1.92908 1.027 2.17508 1.273L17.9331 17.031C18.2331 17.3053 18.4369 17.6065 18.5446 17.9345C18.6522 18.2628 18.7061 18.5975 18.7061 18.9385C18.7061 19.3052 18.6522 19.6527 18.5446 19.981C18.4369 20.309 18.2331 20.6102 17.9331 20.8845L2.16358 36.654C1.91758 36.9 1.61758 37.0212 1.26358 37.0175C0.909914 37.0135 0.609914 36.8885 0.36358 36.6425C0.125247 36.4218 0.00408077 36.1347 8.07747e-05 35.781C-0.00358589 35.427 0.11758 35.127 0.36358 34.881L16.3061 18.9385Z" fill="#B6B6B6"/>
                    </svg>
                </button>
            </div>

            <CustomPagination splide={splideRef.current?.splide} />
        </section>
    );
};

export default SliderKV;