import { useEffect, useState } from "react";

import Logo from "../images/Logo";

import { gtmClick } from "../utils/gtmClick";

const Header = ({ lenis, setInfo }) => {
    const [navOpen, setNavOpen] = useState(false);


    const handleHashChange = () => {
        const target = document.querySelector(window.location.hash);
        const header = document.querySelector("header");
        lenis.scrollTo(target, { offset: window.location.hash === "#mytaiwanway" ? 0 : -header.clientHeight });
    };

    const handleClick = (e, newHash) => {
        e.preventDefault();
        window.history.pushState(null, '', newHash);
        handleHashChange();
    };

    useEffect(() => {
        window.addEventListener('popstate', handleHashChange);

        return () => {
            window.removeEventListener('popstate', handleHashChange);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => { 
        if (lenis) {
            if (window.location.hash) {
                const target = document.querySelector(window.location.hash);
                const header = document.querySelector("header");
                lenis.scrollTo(target, { offset: window.location.hash === "#mytaiwanway" ? 0 : -header.clientHeight });
            }
        }
    }, [lenis]);

    return (
        <>
            <header>
                <a className="logo" href="/">
                    <Logo />
                </a>

                <div className="btn-group">
                    <a className="btn btn-orange" href="#mytaiwanway" onClick={(e) => {
                        handleClick(e, '#mytaiwanway');
                        gtmClick("nav-test");
                    }}>
                        <span className="text text-mo">
                            <i>抽高鐵票</i>
                            <i>立即開測</i>
                        </span>
                    </a>

                    <button className={`btn-menu${navOpen ? " open" : ""}`} onClick={() => { setNavOpen(!navOpen) }}>
                        <span className="line"></span>
                    </button>
                </div>
            </header>

            <nav className={navOpen ? "open" : ""}>
                <div className="bg" onClick={() => { setNavOpen(false); }}></div>
                <ul>
                    <li>
                        <a className="nav-unit" href="#trueway" onClick={(e) => {
                            handleClick(e, '#trueway');
                            setNavOpen(false);
                            gtmClick("nav-menu-1");
                        }}>最真實的台灣WAY</a>
                    </li>
                    <li>
                        <a className="nav-unit" href="#mytaiwanway" onClick={(e) => {
                            handleClick(e, '#mytaiwanway');
                            setNavOpen(false);
                            gtmClick("nav-menu-2");
                        }}>測出你的台灣WAY</a>
                    </li>
                    <li>
                        <a className="nav-unit" href="#tholiday" onClick={(e) => {
                            handleClick(e, '#tholiday');
                            setNavOpen(false);
                            gtmClick("nav-menu-4");
                        }}>高鐵優惠一次到WAY</a>
                    </li>
                    <li>
                        <div className="nav-unit" onClick={(e) => {
                            setInfo(true);
                            setNavOpen(false);
                            gtmClick("nav-menu-3");
                        }}>活動辦法</div>
                    </li>
                    <li>
                        <div className="nav-unit disabled">得獎公告 (10/29 公告)</div>
                    </li>
                </ul>
            </nav>
        </>
    );
};

export default Header;