const SCALE = 2;
const CANVASPADDING = 12;
const PADDING = 12;
const FOOTER_HEIGHT = 96;
const MAINBOX_HEIGHT = [236, 268];
const SMALLBOX_WIDTH = 110;
const SMALLBOX_HEIGHT = 50;
const SUBBOX_HEIGHT = 115;
const URL_LINK = "../../images/outcome/";
const DATE = ["10/15", "10/20", "10/23", "10/26", "11/2"];
const static_images = [
    {
        url: `${URL_LINK}outcome-title.png?20240918`,
        name: "title"
    },
    {
        url: `${URL_LINK}outcome-train.png?20240918`,
        name: "train"
    },
    {
        url: `${URL_LINK}outcome-btn.png?20240918`,
        name: "btn"
    },
    {
        url: `${URL_LINK}emoji-star.png?20240918`,
        name: "star"
    },
    {
        url: `${URL_LINK}emoji-heart.png?20240918`,
        name: "heart"
    },
    {
        url: `${URL_LINK}pic-bg-mask.png?20240918`,
        name: "bgMask"
    },
    {
        url: `${URL_LINK}pic-title_4.png?20240918`,
        name: "spec_title"
    },
    {
        url: `${URL_LINK}pic-character_4.png?20240918`,
        name: "spec_character"
    },
    {
        url: `${URL_LINK}pic-tags_4.png?20240918`,
        name: "spec_tags"
    },
    {
        url: `${URL_LINK}pic-match_4.png?20240918`,
        name: "spec_match"
    },
    {
        url: `${URL_LINK}pic-discount_4.png?20240918`,
        name: "spec_discount"
    }
];

const Data = [
    {
        "bgColor": "#10A4D8",
        "footerColor": "#046486",
        "name": "戀家程度",
        "date": "回家幸運日",
        "type": [
            {
                "name": "隨興等級",
                "match": ["2-1", "3-1", "1-2", "2-2"],
                "link": "https://www.thsrc.com.tw/ArticleContent/fe34c4a1-d274-48ae-bcbe-f8eff4c2faf3"
            },
            {
                "name": "計畫通指數",
                "match": ["2-2", "3-2", "1-1", "2-1"],
                "link": "https://www.thsrc.com.tw/ArticleContent/7039d17d-1463-4c14-ad93-4d491dedcad5"
            },
            {
                "name": "海派等級",
                "match": ["3-3", "2-3", "1-4", "2-4"],
                "link": "https://www.thsrc.com.tw/ArticleContent/29fc80f4-f835-432c-893d-d919a4c3e259"
            },
            {
                "name": "CP值雷達",
                "match": ["2-4", "3-4", "1-3", "2-3"],
                "link": "https://www.thsrc.com.tw/ArticleContent/530e869c-479d-441a-a4b4-61a8166827e9"
            }
        ]
    },
    {
        "bgColor": "#37A143",
        "footerColor": "#1F6926",
        "name": "玩咖 Level",
        "date": "旅行幸運日",
        "type": [
            {
                "name": "隨興等級",
                "match": ["1-1", "3-1", "3-2", "2-2"],
                "link": "https://tholiday.thsrc.com.tw/product-themes/6FD62752-CC09-411C-A142-D77DC68D7056"
            },
            {
                "name": "計畫通指數",
                "match": ["3-2", "1-2", "2-1", "3-1"],
                "link": "https://www.thsrc.com.tw/ArticleContent/f08fe31f-51e1-47c1-a1a1-3dd35cee0b02"
            },
            {
                "name": "海派等級",
                "match": ["1-3", "3-3", "2-4", "3-4"],
                "link": "https://tholiday.thsrc.com.tw/product-themes/3606C8B0-72C1-4A69-8EE4-0B44E3569C3F"
            },
            {
                "name": "CP值雷達",
                "match": ["1-4", "3-4", "2-3", "3-3"],
                "link": "https://www.thsrc.com.tw/ArticleContent/341a5f62-7678-42bb-bd9e-0be634ea3660"
            }
        ]
    },
    {
        "bgColor": "#6E4EC9",
        "footerColor": "#422E8A",
        "name": "工作超人",
        "date": "出差幸運日",
        "type": [
            {
                "name": "隨興等級",
                "match": ["1-1", "2-1", "3-2", "1-2"],
                "link": "https://www.thsrc.com.tw/ArticleContent/fe34c4a1-d274-48ae-bcbe-f8eff4c2faf3"
            },
            {
                "name": "計畫通指數",
                "match": ["1-2", "2-2", "3-1", "1-1"],
                "link": "https://www.thsrc.com.tw/ArticleContent/566d6bff-4427-461d-8b88-b6c4868f75c9"
            },
            {
                "name": "海派等級",
                "match": ["1-3", "2-3", "1-4", "3-4"],
                "link": "https://tshopping.thsrc.com.tw/"
            },
            {
                "name": "CP值雷達",
                "match": ["1-4", "2-4", "3-3", "2-3"],
                "link": "https://www.thsrc.com.tw/ArticleContent/7039d17d-1463-4c14-ad93-4d491dedcad5"
            }
        ]
    },
];

class Outcome {
    constructor({ canvas, setFinalImage, setImagesProgress, setLink }) {
        this.setFinalImage = setFinalImage;
        this.setLink = setLink;
        this.canvas = canvas;
        this.ctx = this.canvas.getContext("2d");
        this.images = {};
        this.setImagesProgress = setImagesProgress

        this.resize(false);
        static_images.forEach(d => {
            this.loadImage(d.url, d.name);
        });

        Data.forEach((d, i) => {
            d.type.forEach((t, j) => {
                this.loadImage(`${URL_LINK}pic-character_${i + 1}-${j + 1}.png?20240918`, `character_${i + 1}-${j + 1}`);
                this.loadImage(`${URL_LINK}pic-match_${i + 1}-${j + 1}.png?20240918`, `match_${i + 1}-${j + 1}`);
                this.loadImage(`${URL_LINK}pic-tags_${i + 1}-${j + 1}.png?20240918`, `tags_${i + 1}-${j + 1}`);
                this.loadImage(`${URL_LINK}pic-title_${i + 1}-${j + 1}.png?20240918`, `title_${i + 1}-${j + 1}`);
                this.loadImage(`${URL_LINK}pic-discount_${i + 1}-${j + 1}.png?20240918`, `discount_${i + 1}-${j + 1}`);
            });
        });
    };

    resize = (memberData) => {
        this.canvas.width = 375 * SCALE;
        this.canvas.height = memberData ? 676 * SCALE : 652 * SCALE;
        this.canvas.style.width = 375 * SCALE + "px";
        this.canvas.style.height = memberData ? 676 * SCALE : 652 * SCALE + "px";

        if (memberData) {
            this.memberData = memberData;
        }
    };

    onLoaded = (img, name) => {
        this.images[name] = img;
        const loaded = Object.keys(this.images).length;
        const total = static_images.length + Data.length * 4 * 5;
        this.setImagesProgress(loaded / total);

        if (loaded === total) {
            this.setImagesProgress(1);
        }
    };

    loadImage = (src, name) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            this.onLoaded(img, name);
        };
    };

    kmText = (text) => {
        const number = Number(text.replace("%", ""));
        const ranges = [
            { min: 0, max: 20, message: "恭喜進入效率新手村" },
            { min: 21, max: 40, message: "看來你是個微行動派" },
            { min: 41, max: 60, message: "根本是效率小天才欸" },
            { min: 61, max: 80, message: "果然是時間管理的狠人" },
            { min: 81, max: 100, message: "原來是重感情冠軍～失敬" },
        ];

        for (const range of ranges) {
            if (number >= range.min && number <= range.max) {
                return range.message;
            }
        }
    };

    roundRect = (x, y, width, height, radius) => {
        this.ctx.beginPath();
        this.ctx.moveTo(x + radius, y);
        this.ctx.lineTo(x + width - radius, y);
        this.ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        this.ctx.lineTo(x + width, y + height - radius);
        this.ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        this.ctx.lineTo(x + radius, y + height);
        this.ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        this.ctx.lineTo(x, y + radius);
        this.ctx.quadraticCurveTo(x, y, x + radius, y);
        this.ctx.closePath();
    };

    textureRoundRect = (x, y, width, height, radius, spec) => {
        this.ctx.save();
        this.roundRect(x, y, width, height, radius);
        this.ctx.clip()
        this.roundRect(x, y, width, height, radius);
        this.ctx.fill();
        this.ctx.globalCompositeOperation = "multiply";
        this.ctx.drawImage(this.images["bgMask"], 0, 0, 1080, 1920, 0, 0, this.canvas.width, this.canvas.height);
        this.ctx.globalCompositeOperation = "source-over";

        if (spec) {
            this.ctx.fillStyle = "#373533";
            this.ctx.fillRect(x, y + height - 25 * SCALE, width, 25 * SCALE);

            this.ctx.letterSpacing = "0em";
            this.ctx.textBaseline = "middle";
            this.ctx.textAlign = "center";

            this.ctx.font = `700 ${16 * 2}px Noto Sans TC`;
            this.ctx.fillText("跟誰最對WAY", this.canvas.width / 2, y + 20 * SCALE);
            this.ctx.drawImage(this.images["spec_match"], 0, 0, 702, 310, x, y + 30 * SCALE, width, 702 / width * 310);

            this.ctx.font = `700 ${12 * 2}px Noto Sans TC`;
            this.ctx.fillStyle = "#FFFFFF";
            this.ctx.fillText("出發幸運日：10/5", this.canvas.width / 2, y + height - 12 * SCALE);
        }

        this.ctx.restore();
    }

    drawStatic = (answer, memberType, isMember) => {
        this.ctx.fillStyle = memberType < 4 ? Data[answer[0]].bgColor : "#535150";
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.globalCompositeOperation = "multiply";
        this.ctx.drawImage(this.images["bgMask"], 0, 0, 1080, 1920, 0, 0, this.canvas.width, this.canvas.height);
        this.ctx.globalCompositeOperation = "source-over";
        this.ctx.fillStyle = memberType < 4 ? Data[answer[0]].footerColor : "#373533";
        this.ctx.fillRect(0, this.canvas.height - (memberType < 4 ? FOOTER_HEIGHT : 80) * SCALE, this.canvas.width, (memberType < 4 ? FOOTER_HEIGHT : 80) * SCALE);

        this.ctx.fillStyle = "#FFFFFF";
        this.textureRoundRect(CANVASPADDING * SCALE, (CANVASPADDING + 24 + 8) * SCALE, this.canvas.width - PADDING * 2 * SCALE, (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) * SCALE, 8 * SCALE);

        if (memberType < 4) {
            this.textureRoundRect(CANVASPADDING * SCALE, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1])) * SCALE, this.canvas.width - PADDING * 2 * SCALE, SUBBOX_HEIGHT * SCALE, 8 * SCALE);
            this.textureRoundRect(CANVASPADDING * SCALE, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT + PADDING) * SCALE, SMALLBOX_WIDTH * SCALE, SMALLBOX_HEIGHT * SCALE, 4 * SCALE);
            this.textureRoundRect((CANVASPADDING + SMALLBOX_WIDTH + 10) * SCALE, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT + PADDING) * SCALE, (SMALLBOX_WIDTH + 1) * SCALE, SMALLBOX_HEIGHT * SCALE, 4 * SCALE);
            this.textureRoundRect((CANVASPADDING + SMALLBOX_WIDTH + 10 + (SMALLBOX_WIDTH + 1) + 10) * SCALE, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT + PADDING) * SCALE, SMALLBOX_WIDTH * SCALE, SMALLBOX_HEIGHT * SCALE, 4 * SCALE);
        } else {
            this.textureRoundRect(CANVASPADDING * SCALE, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1])) * SCALE, this.canvas.width - PADDING * 2 * SCALE, 210 * SCALE, 8 * SCALE, true);
        }

        this.ctx.drawImage(this.images["btn"], 0, 0, 160, 145, this.canvas.width - 90 * SCALE, this.canvas.height - ((memberType < 4 ? FOOTER_HEIGHT : 80) - ((memberType < 4 ? FOOTER_HEIGHT : 80) - 73) / 2) * SCALE, 80 * SCALE, 73 * SCALE);
        this.ctx.drawImage(this.images["train"], 0, 0, 400, 36, 0, this.canvas.height - ((memberType < 4 ? FOOTER_HEIGHT : 80) + 18) * SCALE, 200 * SCALE, 18 * SCALE);
        this.ctx.drawImage(this.images["title"], 0, 0, 894, 144, this.canvas.width / 2 - 150, CANVASPADDING * SCALE, 150 * SCALE, 24 * SCALE);


        this.ctx.drawImage(this.images[memberType < 4 ? `title_${answer[0] + 1}-${memberType + 1}` : "spec_title"], 0, 0, 616, 122, (CANVASPADDING + 20) * SCALE, (CANVASPADDING + 24 + 8 + (isMember ? 45 : 55)) * SCALE, 308 * SCALE, 61 * SCALE);
        this.ctx.drawImage(this.images[memberType < 4 ? `tags_${answer[0] + 1}-${memberType + 1}`: "spec_tags"], 0, 0, 622, 182, (CANVASPADDING + 20) * SCALE, (CANVASPADDING + 24 + 8 + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) - 91 - (isMember ? 16 : 24)) * SCALE, 311 * SCALE, 91 * SCALE);
        this.ctx.drawImage(this.images[memberType < 4 ? `character_${answer[0] + 1}-${memberType + 1}` : "spec_character"], 0, 0, 240, 240, this.canvas.width - (CANVASPADDING + ((answer[0] === 2 && memberType === 2) ? 110 : 120)) * SCALE, (CANVASPADDING + 24 + 8 + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) - ((answer[0] === 2 && memberType === 2) ? 130 : 120)) * SCALE, 120 * SCALE, 120 * SCALE);
        this.ctx.drawImage(this.images[memberType < 4 ? `discount_${answer[0] + 1}-${memberType + 1}` : "spec_discount"], 0, 0, 500, 138, 16 * SCALE, this.canvas.height - ((memberType < 4 ? FOOTER_HEIGHT - 13 : 74)) * SCALE, 250 * SCALE, 69 * SCALE);

        if (memberType < 4) {
            Data[answer[0]].type[memberType].match.forEach((match, i) => {
                this.ctx.drawImage(this.images[`match_${match}`], 0, 0, 160, 128, (CANVASPADDING + 80 * i + 4 * (i + 1) + Math.floor(i / 2) * 10) * SCALE, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + 40) * SCALE, 80 * SCALE, 64 * SCALE);
            });
        }

        this.setLink(memberType < 4 ? Data[answer[0]].type[memberType].link : "https://tshopping.thsrc.com.tw/");

        this.ctx.letterSpacing = "0em";
        this.ctx.textBaseline = "top";
        this.ctx.textAlign = "left";
        this.ctx.fillStyle = memberType < 4 ? Data[answer[0]].bgColor : "#373533";
        this.ctx.font = `900 ${14 * 2}px Noto Sans TC`;
        this.ctx.fillText("高鐵分析你的台灣WAY是...", (CANVASPADDING + 20) * SCALE, (CANVASPADDING + 24 + 8 + 20) * SCALE);

        if (memberType < 4) {
            this.ctx.strokeStyle = Data[answer[0]].bgColor;
            this.ctx.beginPath();
            this.ctx.setLineDash([2 * SCALE, 2 * SCALE]);
            this.ctx.moveTo(this.canvas.width / 2, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1])) * SCALE);
            this.ctx.lineTo(this.canvas.width / 2, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT) * SCALE);
            this.ctx.stroke();
            this.ctx.closePath();
            this.ctx.beginPath();
            this.ctx.setLineDash([2 * SCALE, 2 * SCALE]);
            this.ctx.moveTo(CANVASPADDING * SCALE, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT + PADDING + SMALLBOX_HEIGHT / 2) * SCALE);
            this.ctx.lineTo(this.canvas.width - CANVASPADDING * SCALE, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT + PADDING + SMALLBOX_HEIGHT / 2) * SCALE);
            this.ctx.stroke();
            this.ctx.closePath();
            this.ctx.strokeStyle = "rgba(255, 255, 255, 0.4)";
            this.ctx.beginPath();
            this.ctx.moveTo(this.canvas.width - 100 * SCALE, this.canvas.height - 96 * SCALE);
            this.ctx.lineTo(this.canvas.width - 100 * SCALE, this.canvas.height);
            this.ctx.stroke();
            this.ctx.closePath();

            this.ctx.letterSpacing = "0.1em";
            this.ctx.fillStyle = "#434343";
            this.ctx.textAlign = "center";
            this.ctx.font = `700 ${16 * 2}px Noto Sans TC`;
            this.ctx.fillText("跟誰最對WAY", CANVASPADDING * SCALE + (this.canvas.width - PADDING * 2 * SCALE) / 4, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + 12) * SCALE);
            this.ctx.fillText("互補才夠WAY", CANVASPADDING * SCALE + (this.canvas.width - PADDING * 2 * SCALE) * 3 / 4, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + 12) * SCALE);

            this.ctx.letterSpacing = "0.08em";
            this.ctx.font = `700 ${14 * 2}px Noto Sans TC`;
            this.ctx.fillText(Data[answer[0]].type[memberType].name, (CANVASPADDING + SMALLBOX_WIDTH / 2) * SCALE, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT + PADDING + 6) * SCALE);
            this.ctx.fillText(Data[answer[0]].name, (CANVASPADDING + (SMALLBOX_WIDTH * 3 + 1) / 2 + 10) * SCALE, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT + PADDING + 6) * SCALE);
            this.ctx.fillText(Data[answer[0]].date, (CANVASPADDING + (SMALLBOX_WIDTH * 5 + 1) / 2 + 20) * SCALE, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT + PADDING + 6) * SCALE);

            this.ctx.font = `700 ${12 * 2}px Noto Sans TC`;
            this.ctx.fillText(DATE[Math.floor(Math.random() * DATE.length)], (CANVASPADDING + (SMALLBOX_WIDTH * 5 + 1) / 2 + 20) * SCALE, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT + PADDING + SMALLBOX_HEIGHT / 2 + 6) * SCALE)

            const heart = new Array(answer[1]).fill("");
            const star = new Array(answer[answer.length - 1]).fill("");

            heart.forEach((d, i) => {
                this.ctx.drawImage(this.images["heart"], 0, 0, 60, 60, (CANVASPADDING + SMALLBOX_WIDTH + 10 + 15 * i + (55 - heart.length * 7)) * SCALE, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT + PADDING + SMALLBOX_HEIGHT / 2 + 7) * SCALE, 12 * SCALE, 12 * SCALE);
            });

            star.forEach((d, i) => {
                this.ctx.drawImage(this.images["star"], 0, 0, 60, 60, (CANVASPADDING + 14 * i + (55 - star.length * 7)) * SCALE, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT + PADDING + SMALLBOX_HEIGHT / 2 + 6) * SCALE, 12 * SCALE, 12 * SCALE);
            });
        }

        if (isMember && this.memberData) {
            this.ctx.letterSpacing = "0em";
            this.ctx.fillStyle = "#FFFFFF";
            this.ctx.textBaseline = "top";
            this.ctx.textAlign = "left";
            this.ctx.font = `900 ${14 * 2}px Noto Sans TC`;
            const static_text = this.ctx.measureText("過去一年，你搭高鐵累積");
            this.ctx.font = `700 ${24 * 2}px Noto Sans TC`;
            const km = this.ctx.measureText(`${this.memberData.totalKm}km`);
            const lineStart = (this.canvas.width - (static_text.width + km.width + 4)) / 2;

            this.ctx.font = `700 ${14 * 2}px Noto Sans TC`;
            this.ctx.fillText("過去一年，你搭高鐵累積", lineStart, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT + PADDING + SMALLBOX_HEIGHT + 24 + (memberType < 4 ? 0 : 24)) * SCALE);
            const static_text_2_1 = this.ctx.measureText("勝過");
            const static_text_2_2 = this.ctx.measureText(`的人，${this.kmText(this.memberData.kmPercent)}`);

            this.ctx.fillStyle = "#FAF076";
            this.ctx.font = `900 ${24 * 2}px Noto Sans TC`;
            this.ctx.fillText(`${this.memberData.totalKm}km`, lineStart + static_text.width + 4, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT + PADDING + SMALLBOX_HEIGHT + 18 + (memberType < 4 ? 0 : 24)) * SCALE);
            const percent = this.ctx.measureText(this.memberData.kmPercent);
            const lineStart_2 = (this.canvas.width - (static_text_2_1.width + static_text_2_2.width + percent.width + 8)) / 2;

            this.ctx.fillStyle = "#FFFFFF";
            this.ctx.font = `900 ${14 * 2}px Noto Sans TC`;
            this.ctx.fillText("勝過", lineStart_2, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT + PADDING + SMALLBOX_HEIGHT + 56 + (memberType < 4 ? 0 : 24)) * SCALE);
            this.ctx.fillText(`的人，${this.kmText(this.memberData.kmPercent)}`, lineStart_2 + static_text_2_1.width + 8 + percent.width, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT + PADDING + SMALLBOX_HEIGHT + 56 + (memberType < 4 ? 0 : 24)) * SCALE);

            this.ctx.fillStyle = "#FAF076";
            this.ctx.font = `900 ${24 * 2}px Noto Sans TC`;
            this.ctx.fillText(this.memberData.kmPercent, lineStart_2 + static_text_2_1.width + 4, (CANVASPADDING + 24 + 8 + PADDING + (isMember ? MAINBOX_HEIGHT[0] : MAINBOX_HEIGHT[1]) + SUBBOX_HEIGHT + PADDING + SMALLBOX_HEIGHT + 50 + (memberType < 4 ? 0 : 24)) * SCALE)
        }



        if (this.canvas) {
            const image = this.canvas.toDataURL("image/png");
            this.setFinalImage(image);
        }
    }

    draw = (answer, memberType) => {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.drawStatic(answer, memberType, answer.length === 3);
    }
};

export default Outcome;