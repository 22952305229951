import { useEffect, useState } from "react";

const CustomPagination = ({ splide, setDeco }) => {
    const [current, setCurrent] = useState(0);

    useEffect(() => {
        const setFullHeight = () => {
            const fullHeightDiv = document.querySelector(".section-quiz");
            fullHeightDiv.style.height = window.innerHeight * 2 + "px";
        };

        setFullHeight();
        window.addEventListener("resize", setFullHeight);

        return () => {
            window.removeEventListener("resize", setFullHeight);
        }
    }, []);

    useEffect(() => {
        if (splide) {
            splide.on("move", (index) => {
                setCurrent(index);
                if (setDeco) {
                    setDeco(index % 3);
                }
            });
        }
        // eslint-disable-next-line
    }, [splide]);

    return (
        <div className="pagination-list">
            {Array.from({ length: splide?.length || 0 }, (_, index) => (
                <span
                    key={index}
                    onClick={() => splide?.go(index)}
                    className={`pagination-unit${index === current ? " active" : ""}`}
                >
                </span>
            ))}
        </div>
    );
};

export default CustomPagination;