// https://uattgo.thsrc.com.tw/api/net/v1/clientInfo

// L97umHBppgA2EIXGsnC20001906787TGO

const thsr_api_url = "https://uattgo.thsrc.com.tw";
const url = "https://asia-east1-thsr-taiwan-way.cloudfunctions.net"

export const fetchClientInfo = async () => {
    try {
        const res = await fetch(`${thsr_api_url}/api/net/v1/clientInfo`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        const data = await res.json();
        const { clentInfo } = data;
        return clentInfo;
    } catch (error) {
        console.error(error);
    }
};

export const fetchMemberInfo = async (token, ip) => {
    try {
        const res = await fetch(`${url}/function-1`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({
                "token": token,
                "ip": ip
            })
        });
        const data = await res.json();
        return data;
    } catch (error) {
        console.error(error);
    }
};

export const fetchDraw = async (email, testResult, memberId) => {
    try {
        const res = await fetch(`${url}/receiveData`, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({
                "email": email,
                "testResult": testResult,
                "memberId": memberId
            })
        });
        const data = await res.json();
        return data;
    } catch (error) {
        console.error(error);
    }
};